
import { Component, Vue } from 'vue-property-decorator';
import ChangePassword from '@/components/modals/ChangePassword.vue';
import MainHeader from '@/components/header/MainHeader.vue'
import MyPetsTab from './СabinetTabs/MyPetsTab.vue';
import MyOrdersTab from './СabinetTabs/MyOrdersTab.vue';
import MyDeliveryAddressesTab from './СabinetTabs/MyDeliveryAddressesTab.vue';
import ReviewsTab from './СabinetTabs/ReviewsTab.vue';
import WishlistTab from './СabinetTabs/WishlistTab.vue';
import DiscountsTab from './СabinetTabs/DiscountsTab.vue';
import SubscribedCategoriesTab from './СabinetTabs/SubscribedCategoriesTab.vue';
import EditProfileTab from './СabinetTabs/EditProfileTab.vue';
import { readIsLoggedIn, readUserProfile } from '@/store/main/getters';
import { dispatchUserLogOut } from '@/store/main/actions';

@Component({
  components: {
    ChangePassword,
    MainHeader,
    MyPetsTab,
    MyOrdersTab,
    EditProfileTab,
    MyDeliveryAddressesTab,
    ReviewsTab,
    WishlistTab,
    DiscountsTab,
    SubscribedCategoriesTab,
  },
})

export default class Cabinet extends Vue {
    public isEditing = false;
    public myPets = 'list';
    public myPetsEdit = 'new';
    public petType = 'dog';
    public loadingState: any = {
        page: true,
    };

    public mounted() {
        if (!readIsLoggedIn(this.$store)) {
        this.$router.push('/home');
        }
        this.loadingState.page = false;
    }
    
    public handleLogout() {
        dispatchUserLogOut(this.$store);
        this.$router.push('/login');
    }

  get userProfile() {
    return readUserProfile(this.$store);
  }
}
